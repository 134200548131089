import PropTypes from 'prop-types';
import { DropdownMenuItem as DropdownItem } from '@radix-ui/react-dropdown-menu';

const propTypes = { children: PropTypes.node.isRequired };
const defaultProps = {};

function DropdownMenuItem({ children, ...props }) {
  return (
    <DropdownItem asChild style={{ outline: 'none' }} {...props}>
      <li>{children}</li>
    </DropdownItem>
  );
}
DropdownMenuItem.propTypes = propTypes;
DropdownMenuItem.defaultProps = defaultProps;

export default DropdownMenuItem;
