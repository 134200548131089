import * as DropdownMenu from '@radix-ui/react-dropdown-menu';
import classNames from 'classnames';
import PropTypes from 'prop-types';

const propTypes = {
  trigger: PropTypes.node.isRequired,
  children: PropTypes.node.isRequired,
  disabled: PropTypes.bool,
};
const defaultProps = {
  disabled: false,
};

function DropdownSubmenu({ trigger, disabled, children }) {
  // Render
  return (
    <DropdownMenu.Sub>
      <DropdownMenu.SubTrigger disabled={disabled} style={{ outline: 'none' }}>
        <div
          className={classNames('dropdown-language-submenu-trigger', {
            'dropdown-language-submenu-trigger--disabled': disabled,
          })}
        >
          <div className="dropdown-language-submenu-trigger-content flex justify-between">
            {trigger}
          </div>
        </div>
      </DropdownMenu.SubTrigger>
      <DropdownMenu.Portal>
        <DropdownMenu.SubContent className="dropdown-menu" sideOffset={0} alignOffset={-5}>
          {children}
        </DropdownMenu.SubContent>
      </DropdownMenu.Portal>
    </DropdownMenu.Sub>
  );
}

DropdownSubmenu.propTypes = propTypes;
DropdownSubmenu.defaultProps = defaultProps;

export default DropdownSubmenu;
