import * as Dropdown from '@radix-ui/react-dropdown-menu';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import DropdownMenuItem from './DropdownMenuItem';
import DropdownSubmenu from './DropdownSubmenu';
import ConditionalWrapper from '../ConditionalWrapper';

const propTypes = {
  trigger: PropTypes.node.isRequired,
  children: PropTypes.node.isRequired,
  align: PropTypes.string,
  className: PropTypes.string,
  disablePortal: PropTypes.bool,
};
const defaultProps = { align: 'start', className: null, disablePortal: false };

function DropdownMenu({ trigger, align, children, className, disablePortal }) {
  // Render
  return (
    <Dropdown.Root modal={false}>
      <Dropdown.Trigger asChild>{trigger}</Dropdown.Trigger>
      <ConditionalWrapper
        condition={!disablePortal}
        wrapper={(_children) => <Dropdown.Portal>{_children}</Dropdown.Portal>}
      >
        <Dropdown.Content className={classNames('dropdown-menu', className)} align={align}>
          {children}
        </Dropdown.Content>
      </ConditionalWrapper>
    </Dropdown.Root>
  );
}
DropdownMenu.propTypes = propTypes;
DropdownMenu.defaultProps = defaultProps;

DropdownMenu.Item = DropdownMenuItem;
DropdownMenu.Submenu = DropdownSubmenu;

export default DropdownMenu;
